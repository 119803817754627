/* globals AfterPay, Klarna ,kafkaInfo */
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import {
  checkPending,
  replacePaySuccessReferer
} from '@/public/src/pages/checkout/components/tools.js'
import {
  mergeQueryString,
  parseQueryString,
  extractQueryString,
  getSessionStorage,
} from '@shein/common-function'
import AfterPayCashApp from './cashApp'
import { scanCodePayMethods } from './config'
import errorReport from '@/public/src/pages/common/handlePay/log'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { DLOCAL_PAYMENT_METHODS } from 'public/src/pages/checkout/components/config'

const { lang, langPath, host, SiteUID, NODE_SERVER_ENV } = gbCommonInfo
const SITE_URL = host + langPath
const cbUrl = '/pay/result/unifiedCb'
let scanAbtCodePayMethods = []

function getCountryCode(order = {}) {
  const countryId = order?.country_id
  let countryCode = lang?.toUpperCase()
  if (countryId == 61) {
    countryCode = 'AU'
  } else if (countryId == 38) {
    countryCode = 'CA'
  } else if (countryId == 226) {
    countryCode = 'US'
  } else if (countryId == 225) {
    countryCode = 'GB'
  } else if (countryId == 153) {
    countryCode = 'NZ'
  }
  return countryCode
}

function getResultOcpCbUrl(urlString) {
  // 创建 URL 对象
  const url = new URL(urlString)

  // 获取查询参数
  const params = new URLSearchParams(url.search)

  // 移除不需要的参数
  params.delete('is_vaulting')
  params.delete('type')

  // 更新 URL 对象的查询部分
  url.search = params.toString()

  // 转换 URL 对象回字符串
  const newUrlString = url.toString()
  return newUrlString
}

function getBaseReportInfo ({ order, type = '' } = {}) {
  function getProductType ({
    type = '',
  } = {}) {
    if (type === 'giftcard') return 'gift_card'
    if (type === 'buyprime') return 'paid_membership'
    if (type === 'xtra') return 'paid_shein_saver'
    if (type === 'ocp') return 'one_click_pay'
    return 'normal'
  }
  function formatPaymentAction (payment_method) {
    if (payment_method === 'PayPal-GApaypal') return 'paypal_sdk'
    if (payment_method === 'PayPal-Venmo') return 'venmo_sdk'
    if (payment_method === 'cod') return 'cod'
    if (payment_method.indexOf('klarna-sliceitdirect') > -1 || payment_method.indexOf('cashapp') > -1) return 'channel_sdk'
    return 'redirect_third'
  }

  const payment_action_type = formatPaymentAction(order?.payment_method)
  return {
    payment_action_type,
    uorder_id: order?.relation_billno,
    order_no: order?.billno,
    payment_method: order?.payment_method,
    product_type: getProductType({ type })
  }
}

let _sendErrorReport = function () {}

const checkForm = ({
  order = {},
  vm = {},
  fromCheckout = false,
  type = '',
  extraData = {}
}) => {
  const { billno, payment_method } = order

  let checkout_type = ''
  if (type == 'giftcard') checkout_type = 'giftcard'
  if (type == 'buyprime') checkout_type = 'buyprime'
  if (type == 'ocp') checkout_type = 'ocp'
  if (type == 'xtra') checkout_type = 'xtra'

  const isOCP = type === 'ocp'
  let callbackUrl =
    SITE_URL +
    cbUrl +
    '?bill_no=' +
    billno +
    (checkout_type ? `&checkout_type=${checkout_type}` : '')
  let cancelUrl =
    SITE_URL +
    (type == 'giftcard'
      ? '/giftcard/checkout/again/'
      : '/user/orders/detail/') +
    billno
  if (type == 'buyprime') {
    cancelUrl = SITE_URL + '/user/orders/virtualDetail/' + billno
  }
  if (type == 'xtra') {
    cancelUrl = SITE_URL + '/user/orders/virtualDetail/' + billno
  }
  let failureUrl = callbackUrl
  let pendingUrl = callbackUrl
  const device_language = navigator.language || navigator.browserLanguage || ''
  let pageType = 0 // 是否走自建页面 0: 不走 1: 走

  // dlocal-card的取消返回是post，因为session cookie没设置sameSite，改成跳到中间页后再跳回调
  const dlocalPaymentMethods = gbCommonInfo?.DLOCAL_PAYMENT_METHODS?.length ? gbCommonInfo.DLOCAL_PAYMENT_METHODS : DLOCAL_PAYMENT_METHODS
  if (dlocalPaymentMethods.includes(payment_method)) {
    // callbackFormUrl是用于替换中间页路径的原路由
    callbackUrl =
      SITE_URL +
      '/transform/middlePage?callbackFormUrl=' +
      encodeURIComponent(SITE_URL + cbUrl) +
      '&bill_no=' +
      billno +
      (checkout_type ? `&checkout_type=${checkout_type}` : '')
    failureUrl = pendingUrl = callbackUrl
    cancelUrl = callbackUrl + '&is_cancel=1&paymentCode=' + payment_method
  }

  if (payment_method === 'afterpay-cashapp') {
    callbackUrl = mergeQueryString({
      url: SITE_URL + '/checkout/middlePage',
      mergeObj: {
        callbackUrl,
        paymentMethod: 'afterpay-cashapp',
        countryCode: getCountryCode(order),
        signUpFlag: vm?.signupPayVault?.[payment_method]?.type == '1' && !vm?.signupAccountInfo?.[payment_method]?.id ? 1 : 0
        // signUpFlag: 1
      }
    })
    failureUrl = pendingUrl = callbackUrl
  }

  // 日本便利店现金支付页面
  let cashPayRedirectPath = ''
  if (payment_method === 'adyen-konbini') {
    cashPayRedirectPath = `${SITE_URL}/pay/konbini?billno=${billno}&from=${SaPageInfo?.page_name}`
  } else if (payment_method === 'adyen-multibanco') {
    cashPayRedirectPath = `${SITE_URL}/pay/mtbc?billno=${billno}&from=${SaPageInfo?.page_name}`
  }

  let shopperBankCode = ''
  let shopperBankName = ''
  if (fromCheckout && vm.banksConfig && vm.banksConfig[payment_method]) {
    shopperBankCode = vm.banksConfig[payment_method].bankCode || ''
    shopperBankName = vm.banksConfig[payment_method].bankCodeText || ''
  } else if (vm.banksConfig && vm.banksConfig[payment_method]) {
    shopperBankCode = vm.banksConfig[payment_method].bankCode || ''
    shopperBankName = vm.banksConfig[payment_method].bankCodeText || ''
  }


  // 一键购处理 adyen-ideal/checkout-ideal
  if (isOCP) {
    shopperBankCode =
      vm?.shopperBankCode || order?.shopperBankCode || shopperBankCode
    shopperBankName =
      vm?.shopperBankName || order?.shopperBankName || shopperBankName
  }

  let phone = vm.qiwiPayPhone || ''
  if (payment_method == 'adyen-mbway') {
    phone = vm.mbwayInfo.code + vm.mbwayInfo.phone
    cashPayRedirectPath = `${SITE_URL}/MB-WAY-a-1197.html?billno=${billno}&payment_code=${payment_method}`
    //测试环境用其它文章页调试使用
    if (['debug', 'localhost'].includes(NODE_SERVER_ENV)) {
      // cashPayRedirectPath = `${SITE_URL}/articlePreview?scene_key=article&content_id=8AD496B8393C459C84B945A0A08EE5EE&billno=${billno}&payment_code=${payment_method}`
      cashPayRedirectPath = `${SITE_URL}/articlePreview?scene_key=article&content_id=48F0FF16CE54411DB6178300A8C3ED01&billno=${billno}&payment_code=${payment_method}`
    }
  }

  let email = (vm.emailTips && vm.emailTips.val) || ''
  // 南非capitec银行 
  // if(payment_method === 'dlocal-eft' && vm?.capitecDialog?.bankInfo?.name === 'Capitec bank' ){
  const isNotCapitecBank = payment_method === 'dlocal-eft' && vm?.capitecDialog?.bankInfo?.name != 'Capitec bank'
  if(vm?.capitecDialog?.collectEmail && !isNotCapitecBank){
    email = vm.capitecDialog.email || ''
  }
  if(vm?.capitecDialog?.collectPhoneNumber && !isNotCapitecBank){
    phone = vm.capitecDialog.phone || ''
  }

  // shein 页面，跳转扫码支付 => adyen-pix 特殊处理下
  // 如果如何abtest的支付方式，也需要跳转自建页面，vm?.paymentCustomizeFlow='ebanx-pix,dlocal-pix,ebanx-boleto'
  // 用includes判断比indexOf更准确一些
  // console.log('zj vm?.paymentCustomizeFlow', vm?.paymentCustomizeFlow)
  const paymentCustomizeFlowFormat =
    typeof vm?.paymentCustomizeFlow === 'string'
      ? vm?.paymentCustomizeFlow?.split(',')
      : []
  if (
    scanCodePayMethods.includes(payment_method) ||
    paymentCustomizeFlowFormat.includes(payment_method)
  ) {
    let urlHost = 'sheinisolatedform'
    scanAbtCodePayMethods = paymentCustomizeFlowFormat
    cashPayRedirectPath = `${SITE_URL}/pay/${urlHost}?billno=${billno}&paymentCode=${payment_method}&giftcard=${
      type == 'giftcard' ? 1 : 0
    }`
    pageType = 1
  }

  const formData = {
    billno: billno,
    device_language,
    paymentCode: payment_method,
    callbackUrl,
    cancelUrl,
    pendingUrl,
    failureUrl,
    cashPayRedirectPath,

    shopperBankCode,
    shopperBankName,
    phone,
    email,
    payerAccount:
      (vm.paymentOption &&
        vm.paymentOption[payment_method] &&
        vm.paymentOption[payment_method].payerAccount) ||
      '',
    sessionId:
      window.KlarnaSDKIsLoad &&
      vm.showWidgetForm == 2 &&
      vm.needPayInlinePaymentMethods &&
      vm.needPayInlinePaymentMethods.includes(payment_method) &&
      vm.payInlineSessionInfo &&
      vm.payInlineSessionInfo[payment_method]
        ? vm.payInlineSessionInfo[payment_method].sessionId
        : '',
    userAgent: navigator.userAgent,
    pageType,
    checkout_type
  }

  //如果是签约用户 - 仅PayPal-GApaypal
  if (vm?.paypalGaVault?.type == '1' && payment_method == 'PayPal-GApaypal') {
    formData.signUpFlag = 1
    //是否有历史签约，历史签约继续签约不初始化sdk，但要上送paypalDeviceId
    if (vm?.ppgvAccountInfo?.id) {
      formData.signUpId = vm.ppgvAccountInfo.id
      formData.channelDeviceFingerId = vm.paypalDeviceId || ''
      formData.signUpFlag = 0
    }
  }

  // afterpay
  // if (vm?.afterPayVault?.switch && vm?.afterPayVault?.type == '1' && payment_method == 'afterpay-card') {
  //   formData.signUpFlag = 1
  //   if(vm?.afterPayAccountInfo?.id) {
  //     formData.signUpId = vm?.afterPayAccountInfo?.id
  //     formData.signUpFlag = 0
  //   }
  // }

  // 签约支付
  if (!['PayPal-GApaypal'].includes(payment_method)) {
    if (vm?.signupPayVault?.[payment_method]?.type == '1') {
      formData.signUpFlag = 1
    }
    if (vm?.signupAccountInfo?.[payment_method]?.id) {
      formData.signUpId = vm.signupAccountInfo[payment_method].id
      formData.signUpFlag = 0
    }
    if (vm?.signupPayVault?.[payment_method]?.useOneTimePay) {
      delete formData.signUpId
      delete formData.signUpFlag
    }
  }
  console.log('vm>>', vm, 'formData=', formData)

  const payload = vm.paypalGaPayLoad
  if (
    payload &&
    payload.nonce &&
    vm.chosenChannel == 'paypal' &&
    ['PayPal-GApaypal', 'PayPal-bnpl'].includes(payment_method) &&
    vm.paypalGaBtn
  ) {
    const { details, nonce } = payload
    const { shippingAddress } = details
    //如果是有值的对象
    if (shippingAddress && shippingAddress.postalCode) {
      formData.channelShipAddress = JSON.stringify(shippingAddress)
    }
    // 风控信息
    formData.channelDeviceFingerId = vm.paypalDeviceId || ''
    formData.userAgent = navigator.userAgent
    formData.sessionId = nonce
  }

  if(payment_method === 'PayPal-GApaypal'){
    // vipOrderSource 会员标识
    formData.vipOrderSource = vm?.vipOrderSource || vm?.primeActivityType || vm?.orderInfo?.vip_order_source || 0
  }

  const paypalVenmoPayLoad = vm.paypalVenmoPayLoad
  if (
    payment_method == 'PayPal-Venmo' &&
    paypalVenmoPayLoad &&
    paypalVenmoPayLoad.nonce &&
    vm.chosenChannel == 'paypal' &&
    vm.paypalVenmoBtnShow
  ) {
    formData.channelDeviceFingerId = vm.paypalVenmoDeviceId || ''
    formData.userAgent = navigator.userAgent
    formData.sessionId = paypalVenmoPayLoad.nonce
  }

  if (payment_method == 'paidy-paylater') {
    formData.sessionId = extraData?.sessionId || ''
  }

  if (isOCP) {
    let callbackUrl =
      host +
      langPath +
      '/pay/result/ocpUnifiedCb?billno=' +
      billno +
      `&isOcp=1&ocpCbUrl=${encodeURIComponent(location.href)}`
    const dlocalPaymentMethods = gbCommonInfo?.DLOCAL_PAYMENT_METHODS?.length ? gbCommonInfo.DLOCAL_PAYMENT_METHODS : DLOCAL_PAYMENT_METHODS
    if (dlocalPaymentMethods.includes(payment_method)) {
      // callbackFormUrl是用于替换中间页路径的原路由
      callbackUrl = SITE_URL + '/transform/middlePage?callbackFormUrl=' + encodeURIComponent(callbackUrl)
    }
    // fix afterpay-cashapp 一键购支付回调
    if (['afterpay-cashapp'].includes(payment_method)) {
      // hack afterpay渠道callbackUrl不能超长 尽量缩短url长度
      // cbUrl payCode
      callbackUrl = host +
      langPath +
      '/pay/result/ocpUnifiedCb?billno=' +
      billno +
      `&isOcp=1&cb=${encodeURIComponent(location.pathname)}`
      callbackUrl = mergeQueryString({
        url: SITE_URL + '/checkout/middlePage',
        mergeObj: {
          cbUrl: callbackUrl,
          payCode: 'afterpay-cashapp',
          countryCode: getCountryCode(order),
          signUpFlag: vm?.signupPayVault?.[payment_method]?.type == '1' && !vm?.signupAccountInfo?.[payment_method]?.id ? 1 : 0
          // signUpFlag: 1
        }
      })
    }
    formData.callbackUrl =
      formData.failureUrl =
      formData.pendingUrl =
        callbackUrl
    formData.cancelUrl = mergeQueryString({
      url: callbackUrl,
      mergeObj: {
        ocpCancel: 1
      }
    })

    /**
     * 一些支付方式默认兜底处理，一键购取消标志 isOCP = 1 ocpCancel = 1 一键购中间页
     */
    // if (['afterpay-cashapp'].includes(payment_method)) {
    //   let callbackUrl = mergeQueryString({
    //     url: SITE_URL + '/checkout/middlePage',
    //     mergeObj: {
    //       callbackUrl,
    //       paymentMethod: 'afterpay-cashapp',
    //       countryCode: getCountryCode(order),
    //       signUpFlag: vm?.signupPayVault?.[payment_method]?.type == '1' && !vm?.signupAccountInfo?.[payment_method]?.id ? 1 : 0
    //       // signUpFlag: 1
    //     }
    //   })
    //   formData.callbackUrl = callbackUrl
    // }
  }

  return formData
}

const unifiedPay = async ({
  order = {},
  vm = {},
  errorHandle,
  makeForm,
  formData,
  fromCheckout = false,
  type = '',
  successHandle,
  failHandle,
  cancelHandle
}) => {
  // const giftcardPath = type == 'giftcard' ? '/giftcard' : ''
  // 判断是否是普通订单，非礼品卡和单独购
  const isNormalOrder =
    ['giftcard', 'buyprime', 'ocp', 'xtra'].indexOf(type) === -1
  const isOCP = type === 'ocp'
  const isBuyXtra = type === 'xtra' || ['xtra', 'xtra_again'].includes((vm?.TYPEPAY_PROS || '').toLocaleLowerCase())
  const isBuyPrime = type === 'buyprime' || ['buyprime', 'buyprime_again'].includes((vm?.TYPEPAY_PROS || '').toLocaleLowerCase())

  let unifiePath = ''
  switch (type) {
    case 'giftcard':
      unifiePath = '/giftcard'
      break
    default:
      break
  }
  const { billno, payment_method, sub_billnos = [] } = order
  const baseReportInfo = getBaseReportInfo({ order, type })
  _sendErrorReport = function (payment_error_scene = '', params = {
    failure_type: '',
    client_url: '',
    status_code: '',
    description: ''
  }) {
    try {
      errorReport?.sendPaymentError?.(payment_error_scene, {
        failure_type: params?.failure_type || '',
        product_type: baseReportInfo.product_type,
        payment_action_type: baseReportInfo.payment_action_type || '',
        client_url: params?.client_url || '',
        error_data: {
          status_code: params?.status_code,
          uorder_id: baseReportInfo?.uorder_id,
          order_no: baseReportInfo?.order_no,
          payment_method: baseReportInfo?.payment_method,
          description: params?.description || '',
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  const childBillnoList =
    sub_billnos && sub_billnos?.map(v => v.sub_billno).join(',') // 子订单号
  formData.childBillnoList = childBillnoList || ''

  formData.neurPayId = kafkaInfo.neurPayId =
    billno + '_' + Math.floor(Math.random() * 99999)
  formData.neurStep = kafkaInfo.neurStep = 1
  kafkaInfo.startTime = +new Date()
  // const url = (kafkaInfo.callUrl = SITE_URL + '/pay/unifiedPay')
  kafkaInfo.billno = billno
  kafkaInfo.paymentMethod = payment_method
  formData.forterDeviceFingerprintID = window.forterDeviceId || ''
  formData.forterSiteId = window.forterSiteId || ''
  if (window.isRiskifiedLoaded) {
    formData.riskifiedDeviceFingerprintID = window.riskifiedDeviceId || ''
  } else {
    formData.deviceFingerId = window.GB_cybersource_uuid || ''
  }

  // 同盾指纹
  if (window._fmOpt?.__blackbox) {
    formData.blackbox = window._fmOpt.__blackbox
  } else {
    // 未获取到同盾指纹上报
    apiReport.report({
      apiPath: 'tongdun/before-payment/error',
      sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
      billno: order.billno,
      paymentMethod: order.payment_method
    })
  }

  // blik code
  if (['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(payment_method)) {
    formData.verificationCode = vm.blikCode.codeList || ''
  }

  if (payment_method == 'PayPal-GApaypal') {
    apiReport.report({
      apiPath: 'paypalga-process',
      msg: vm?.processList || ['none'],
      err: vm?.paypalGaBtn,
      scene: vm?.paypalBtnShow,
      abtInfo: vm?.payInlineAbtInfo,
      billno: billno
    })
  }

  if (window.forterSDKLoaded && !window.forterDeviceId) {
    //js初始化成功，且在发起支付时，前端给后端的指纹id为空
    apiReport.report({
      apiPath: 'forter/before-payment/error',
      siteuid: SiteUID,
      scene: 'js sdk init success but forter deviceId is null',
      billno: billno,
      paymentMethod: payment_method
    })
  }
  if (type != 'giftcard' && !window.GB_cybersource_uuid) {
    // 非礼品卡，发起支付时，前端给后端的指纹id为空
    apiReport.report({
      apiPath: 'cybs/before-payment/error',
      siteuid: SiteUID,
      scene: 'cybs deviceId is null',
      billno: billno,
      paymentMethod: payment_method
    })
  }

  if (window.isRiskifiedLoaded && !window.riskifiedDeviceId) {
    // 礼品卡，发起支付时，前端给后端的指纹id为空
    apiReport.report({
      apiPath: 'riskified/before-payment/error',
      siteuid: SiteUID,
      scene: 'riskified deviceId is null',
      billno: billno,
      paymentMethod: payment_method
    })
  }
  schttp({
    url: '/api/pay/unifiedPay/create',
    method: 'POST',
    data: formData
  })
    .then(res => {
      kafkaInfo.sendData({ res, resStatus: 200, formData })

      if (!fromCheckout) {
        vm.changeRootStatus({
          loading: false
        })
      }

      if (res?.code != 0 || res?.info?.error_code) {
        _sendErrorReport('unified_pay_create_fail', {
          failure_type: 'api',
          client_url: '/pay/unifiedPay/create',
          status_code: res?.info?.error_code || res?.code,
          description: res?.info?.error_msg || res?.info?.show_error_msg
        })
      }

      if (res && res.code == 0 && res.info) {
        let {
          action,
          paramList,
          actionUrl,
          result,
          error_code,
          error_msg,
          is_guide,
          neurData,
          show_error_msg,
          pending
        } = res.info

        // 跳转支付
        if (action == 'redirect') {
          // 未获取到actionUrl时上报
          if (!actionUrl) {
            errorActionUrlReport({ payment_method, billno })
          }
          let jumpUrl = SITE_URL + unifiePath + '/user/orders/detail/' + billno
          if (type == 'buyprime') {
            jumpUrl = `${SITE_URL}/user/orders/virtualDetail/${billno}`
          }
          if (type == 'xtra') {
            jumpUrl = `${SITE_URL}/user/orders/virtualDetail/${billno}`
          }
          if (type == 'giftcard') {
            jumpUrl = `${SITE_URL}/user/orders/giftcard_detail/${billno}`
          }
          if (
            ['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(payment_method) &&
            !actionUrl
          ) {
            vm.blikCode = Object.assign(vm.blikCode, {
              pending: true,
              error: false,
              errorText: '',
              jumpUrl
            })
            makeForm({ error_code })
            return
          }
          // wiki.pageId=910685196
          if (payment_method === 'nuvei-bizum' && !actionUrl) {
            setTimeout(() => {
              window.location.href = jumpUrl
            }, 300)
            return
          }
          window.location.href = actionUrl
          if (
            [
              'alipay-kakaopay',
              'alipay-truemoney',
              'alipay-gcash',
              'alipay-touchngo'
            ].includes(payment_method)
          ) {
            let timer = setTimeout(() => {
              vm.showPayResult = true
              vm.loadingShow = false
              timer && clearTimeout(timer)
            }, 2000)
          }
        } else if (action == 'render' && paramList) {
          // afterpay需加载渠道js
          if (['afterpay-card', 'afterpay-clearpay'].includes(payment_method)) {
            if (window.AfterPay) {
              const countryId = order.country_id
              let countryCode = lang.toUpperCase()
              if (countryId == 61) {
                countryCode = 'AU'
              } else if (countryId == 38) {
                countryCode = 'CA'
              } else if (countryId == 226) {
                countryCode = 'US'
              } else if (countryId == 225) {
                countryCode = 'GB'
              } else if (countryId == 153) {
                countryCode = 'NZ'
              }

              AfterPay.initialize({ countryCode })
              AfterPay.redirect({ token: paramList.token })
            } else {
              errorHandle(res, billno)
            }
            return
          }

          if (payment_method === 'afterpay-cashapp') {
            if (window.AfterPay) {
              const countryId = order.country_id
              let countryCode = lang.toUpperCase()
              if (countryId == 61) {
                countryCode = 'AU'
              } else if (countryId == 38) {
                countryCode = 'CA'
              } else if (countryId == 226) {
                countryCode = 'US'
              } else if (countryId == 225) {
                countryCode = 'GB'
              } else if (countryId == 153) {
                countryCode = 'NZ'
              }
              vm.changeRootStatus?.({ loading: true })
              vm.loadingShow = true
              const afterPayCashApp = new AfterPayCashApp()
              afterPayCashApp
                .renderCashAppPay({ countryCode })
                .then(() => {
                  // let isComplete = false
                  vm.changeRootStatus?.({ loading: false })
                  vm.loadingShow = false
                  let requestOnFileGrant = formData.signUpFlag == 1
                  // let requestOnFileGrant = true
                  afterPayCashApp.initializeForCashAppPay({
                    requestOnFileGrant,
                    params: {
                      token: paramList.token,
                      countryCode
                    },
                    onComplete() {
                      apiReport?.report?.({
                        apiPath: 'afterpay-cashapp/on-complete',
                        billno: billno
                      })
                      afterPayCashApp?.restartCashAppPay()
                    },
                    onCancel() {
                      apiReport?.report?.({
                        apiPath: 'afterpay-cashapp/on-cancel',
                        billno: billno
                      })
                      afterPayCashApp?.restartCashAppPay()
                      if (isOCP) {
                        return cancelHandle()
                      }
                      if (fromCheckout) {
                        window.location.href = formData.cancelUrl
                      } else {
                        vm.loadingShow = false
                        vm.changeRootStatus?.({ loading: false })
                      }
                    },
                    onFail(err = { msg: '' }) {
                      apiReport?.report?.({
                        apiPath: 'afterpay-cashapp/on-fail',
                        billno: billno,
                        err: err?.msg || ''
                      })
                      if (isOCP) {
                        return errorHandle(res)
                      } else {
                        setTimeout(() => {
                          let jumpUrl =
                            SITE_URL +
                            unifiePath +
                            '/pay/result/fail?billno=' +
                            billno +
                            '&is_guide=1'
                          window.location.href = jumpUrl
                        }, 600)
                      }
                    },
                    onSuccess: isOCP
                      ? (redirectUrl) => {
                        const params = parseQueryString(extractQueryString(redirectUrl))
                        schttp({
                          url: '/pay/ocp/unifiedCb/post',
                          method: 'POST',
                          params
                        }).then(res => {
                          unifiedCbHandle({
                            result: res,
                            successHandle,
                            errorHandle
                          })
                        })
                        return
                      }
                      : null
                  })
                  let timer = setTimeout(() => {
                    vm.showPayResult = true
                    vm.loadingShow = false
                    vm.changeRootStatus?.({ loading: false })
                    timer && clearTimeout(timer)
                  }, 8000)
                })
                .catch(error => {
                  apiReport?.report?.({
                    apiPath: 'afterpay-cashapp/render-cash-error',
                    billno: billno
                  })
                  console.error(error)
                  vm.loadingShow = false
                  vm.changeRootStatus?.({ loading: false })
                  afterPayCashApp?.restartCashAppPay()
                  errorHandle(res, billno)
                })
            } else {
              apiReport?.report?.({
                apiPath: 'afterpay-cashapp/init-sdk-error',
                billno: billno
              })
              vm.loadingShow = false
              vm.changeRootStatus?.({ loading: false })
              errorHandle(res, billno)
            }
            return
          }

          if (
            [
              'adyen-konbini',
              'adyen-multibanco',
              ...scanCodePayMethods,
              ...scanAbtCodePayMethods
            ].includes(payment_method)
          ) {
            const {
              reference = '',
              cashPayRedirectPath = '',
              entity = ''
            } = paramList || {}

            if (payment_method == 'adyen-konbini') {
              window.localStorage.setItem('ADYEN_KONBINI_REFERENCE', reference)
            } else if (payment_method == 'adyen-multibanco') {
              window.localStorage.setItem(
                'MULTIBANCO_REFERENCE',
                JSON.stringify({ reference, entity })
              )
            } else if (
              [...scanCodePayMethods, ...scanAbtCodePayMethods].includes(
                payment_method
              )
            ) {
              // 兜底
              if (
                (!paramList?.qrCodeData && payment_method == 'adyen-pix') ||
                !cashPayRedirectPath
              ) {
                window.location.href =
                  SITE_URL +
                  unifiePath +
                  '/pay/result/fail?billno=' +
                  billno +
                  '&is_guide=' +
                  is_guide
                return
              }
              try {
                window.localStorage.setItem(
                  'FORMPAY',
                  JSON.stringify({
                    giftcard: type === 'giftcard' ? 1 : 0,
                    ...paramList
                  })
                )
              } catch (e) {
                if (e.name === 'QuotaExceededError') {
                  // 本地存储已满，采取相应措施
                  if (isBuyXtra || isBuyPrime) {
                    location.href = `${SITE_URL}/user/orders/virtualDetail/${billno}`
                    return
                  }
                  location.href = `${SITE_URL}/user/orders/detail/${billno}`

                  console.error('Local storage quota exceeded!')
                }
              }
            }
            apiReport.report({
              apiPath: '/pay-cash-init-value',
              pay_reference_value: reference ? 1 : 0,
              pay_entity_value: entity ? 1 : 0,
              siteuid: SiteUID,
              payment_method,
              billno,
              brand: gbCommonInfo.IS_RW ? 'romwe' : 'shein',
              site: 'pwa'
            })

            window.location.href = cashPayRedirectPath
            return
          }

          if (
            vm.needPayInlinePaymentMethods &&
            vm.needPayInlinePaymentMethods.includes(payment_method) &&
            formData.sessionId
          ) {
            const payInlineAbtInfo = vm.payInlineAbtInfo || {} // klarna等分期直连支付方式abt
            for (let key in payInlineAbtInfo) {
              if (
                payInlineAbtInfo[key].includes(payment_method) &&
                key == 'klarna'
              ) {
                vm.loadingShow = false
                klarnaInline(
                  vm,
                  order,
                  paramList,
                  formData.callbackUrl,
                  fromCheckout,
                  neurData,
                  {
                    type,
                    successHandle,
                    errorHandle
                  }
                )
              }
            }
            return
          }

          // form表单提交
          makeForm(paramList, actionUrl)
        } else if (action == 'direct' && result == 0 && pending == 1) {
          window.location.href = checkPending({
            billno,
            isGift: type == 'giftcard',
            isBuyPrime: type == 'buyprime' || ['buyprime', 'buyprime_again'].includes((vm?.TYPEPAY_PROS || '').toLocaleLowerCase())
          })
        } else if (action == 'direct') {
          // 直接返回支付结果
          let jumpUrl =
            SITE_URL +
            unifiePath +
            '/pay/result/fail?billno=' +
            billno +
            '&is_guide=' +
            is_guide

          if (result == 1) {
            jumpUrl =
              SITE_URL +
              unifiePath +
              '/pay/result/success?type=' +
              payment_method +
              '&billno=' +
              billno
            if (typeof successHandle === 'function') {
              return successHandle(res, jumpUrl)
            }
            if (type != 'giftcard') {
              replacePaySuccessReferer()
            }
          } else if (error_code) {
            jumpUrl += '&error_code=' + error_code
          }
          // 	TR-17830 用于在支付结果页记录用户是否选择签约
          if (payment_method == 'PayPal-GApaypal') {
            jumpUrl = mergeQueryString({
              url: jumpUrl,
              mergeObj: {
                is_vaulting: vm?.paypalGaVault?.type == '1' ? '1' : '0'
              }
            })
          }

          if (['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(payment_method)) {
            makeForm({ error_code })
            if ([7539, 7584].includes(+error_code) && result == '0') {
              vm.blikCode = Object.assign(vm.blikCode, {
                error: true,
                loading: false,
                errorText: show_error_msg || error_msg
              })
              return
            }
          }

          // pp多帐号解约异常提示
          // wiki.pageId=1030661015
          if (
            ['PayPal-GApaypal', 'PayPal-Venmo'].includes(payment_method) &&
            error_code == 7581
          ) {
            // 下单页
            if (vm && vm.blackMask) {
              vm.blackMask.billno = order.billno || ''
              vm.blackMask.errorCode = res.info.error_code
            }
            errorHandle(res, order.billno, {
              errorMsg: res.info.show_error_msg
            })
            return
          }
          // 普通订单 paypalga 上送地址报错优化 jira.FR-11235
          if (
            isNormalOrder &&
            payment_method === 'PayPal-GApaypal' &&
            ['7583', '7511', '7527'].includes(`${error_code}`)
          ) {
            if (fromCheckout) {
              vm.changeParamsStatus({
                type: 'blackMask',
                params: {
                  billno: order.billno || '',
                  errorCode: res.info.error_code,
                  type: 'addressError'
                }
              })
            }
            errorHandle(res, order.billno, {
              errorMsg: res.info.show_error_msg,
              isAddressError: true
            })
            return
          }

          if (
            !['adyen-blikdirect', 'alipay-blik', 'routepay-blik'].includes(payment_method) &&
            res?.info?.show_error_msg
          ) {
            //jumpUrl += '&ft=' + encodeURIComponent(res.info.show_error_msg)
            if (typeof failHandle === 'function') {
              return failHandle(res)
            }
            makeForm(
              {
                result: JSON.stringify(res)
              },
              jumpUrl
            )
            return
          }
          if (typeof failHandle === 'function') {
            return failHandle(res, jumpUrl)
          }
          window.location.href = jumpUrl
        }
      } else if (res && res.code == 300206) {
        window.location.href = SITE_URL + '/user/logout'
      } else {
        // 异常时上报 wiki.pageId=910685196
        if (['nuvei-bizum', 'nuvei-konbini'].includes(payment_method)) {
          errorActionUrlReport({ billno, payment_method })
        }
        errorHandle(res, order.billno)
      }
    })
    .catch(err => {
      let resStatus = 0 //默认超时，传0
      if (err.status != 0) resStatus = err.status
      kafkaInfo.sendData({ resStatus, formData })
      // 异常时上报 wiki.pageId=910685196
      if (['nuvei-bizum', 'nuvei-konbini'].includes(payment_method)) {
        errorActionUrlReport({ billno, payment_method })
      }
      errorHandle(null, order.billno)
    })
}

function initKafka() {
  if (typeof window == 'undefined') return

  window.kafkaInfo = {
    startTime: 0,
    endTime: 0,
    descriptions: '',
    sendData({ res = {}, resStatus, formData }) {
      kafkaInfo.nextResponse = JSON.stringify(res)
      kafkaInfo.nextHttpCode = resStatus
      kafkaInfo.kafkaAddDes({ formData, res })

      kafkaInfo.endTime = +new Date()

      let data = {
        neurStep: kafkaInfo.neurStep,
        neurPayId: kafkaInfo.neurPayId,
        myName: 'pwa-client',
        startTime: kafkaInfo.startTime,
        endTime: kafkaInfo.endTime,
        costTime: kafkaInfo.endTime - kafkaInfo.startTime,
        myStatus: 1,
        descriptions: kafkaInfo.descriptions,
        callUrl: kafkaInfo.callUrl,
        nextStatus: kafkaInfo.nextHttpCode == 200 ? 1 : 2,
        nextHttpCode: kafkaInfo.nextHttpCode,
        nextResponse: kafkaInfo.nextResponse,
        billno: kafkaInfo.billno,
        paymentMethod: kafkaInfo.paymentMethod,
        siteUid: SiteUID,
        extend: kafkaInfo.nextHttpCode == 0 ? 'timeout' : ''
      }
      schttp({
        url: '/api/pay/sendKafka/create',
        method: 'POST',
        data
      })
      kafkaInfo.descriptions = ''
    },
    kafkaAddDes({ formData }) {
      kafkaInfo.descriptions = '支付开始'
      if (!formData) return
      if (formData.channelDeviceFingerId) {
        kafkaInfo.descriptions += '风控数据采集正常\n'
      }
      if (kafkaInfo.applePayMid) {
        kafkaInfo.descriptions +=
          '获取applepay商户号成功：' + kafkaInfo.applePayMid + '\n'
      }
      if (formData.applePayInfo) {
        kafkaInfo.descriptions += '完成applepay sdk交互，获取授权正常\n'
      }
      if (window.googlePayment && googlePayment.merchantName) {
        kafkaInfo.descriptions +=
          '获取googlepay商户号成功：' + googlePayment.merchantName + '\n'
      }
      if (formData.googlePayInfo) {
        kafkaInfo.descriptions += '完成googlepay sdk交互，获取授权正常\n'
      }
    }
  }
}

function unifiedCbHandle({ result, successHandle, errorHandle } = {}) {
  if (result && result.code === '0' && result.info) {
    const { action, url, pending } = result.info || {}
    if (result.info.result == 1) {
      successHandle(result)
    } else if (action == 'redirect') {
      window.location.href = url
    } else if (action == 'direct' && result.info.result == 0 && pending == 1) {
      successHandle(result)
    } else {
      errorHandle(result)
    }
  } else {
    errorHandle(result)
  }
}

initKafka()

function klarnaInline(
  vm,
  order,
  paramList,
  callbackUrl,
  fromCheckout,
  neurData,
  { type, successHandle, errorHandle } = {}
) {
  const { payment_method } = order
  if (window.KlarnaSDKIsLoad && vm.payInlineSessionInfo[payment_method]) {
    try {
      let paymentsData = {}
      if (paramList?.shipping_address) {
        paymentsData.shipping_address = paramList.shipping_address || {}
      }
      if (paramList?.billing_address) {
        paymentsData.billing_address = paramList.billing_address
      }
      SILogger.logInfo({
        tag: 'Klarna_sdk_log',
        message: 'klarna start authorize',
        params: {
          scene: 'authorize',
          client_token: vm.payInlineSessionInfo[payment_method].clientToken || '',
          sessionId: vm.payInlineSessionInfo[payment_method].sessionId || '',
          billno: order?.billno || '',
          cacheKey: vm?.nowInlineCacheKey || '',
          url: typeof window != 'undefined' ? window.location.href : ''
        }
      })
      const loadClientCompareKey = getSessionStorage?.('klarnaClientCompareKey') || ''
      if (loadClientCompareKey !== vm.payInlineSessionInfo?.[payment_method]?.clientToken || '') {
        SILogger.logWarn({
          tag: 'Klarna_sdk_log',
          message: 'klarna token compare error',
          params: {
            scene: loadClientCompareKey ? 'compare' : 'emptyLoadKey',
            billno: order?.billno || '',
            url: typeof window != 'undefined' ? window.location.href : ''
          }
        })
      }
      loadClientCompareKey && sessionStorage.removeItem('klarnaClientCompareKey')

      const paymentMethodCategory =
        vm.payInlineSessionInfo[payment_method].paymentMethodCategory
      Klarna.Payments.authorize(
        {
          payment_method_category: paymentMethodCategory
        },
        paymentsData,
        function (res) {
          // authorize~callback
          apiReport.report({
            apiPath: 'inline/klarna/report',
            siteuid: gbCommonInfo.SiteUID,
            paymentsData: {
              keys: Object.keys(paymentsData || {}).join(','), // 信息脱敏
              payment_method_category: paymentMethodCategory
            },
            message: JSON.stringify(res || {})
          })
          if (res.approved && res.authorization_token) {
            callbackUrl +=
              '&paymentCode=' +
              payment_method +
              '&gatewayPayNo=' +
              paramList.gateway_pay_no +
              '&token=' +
              res.authorization_token
            if (neurData && neurData.neurStep && neurData.neurPayId) {
              let neurStep = neurData.neurStep + 1
              callbackUrl +=
                '&neurStep=' + neurStep + '&neurPayId=' + neurData.neurPayId
            }
            if (type === 'ocp') {
              const params = parseQueryString(extractQueryString(callbackUrl))
              schttp({
                url: '/pay/ocp/unifiedCb/post',
                method: 'POST',
                params
              }).then(res => {
                unifiedCbHandle({
                  result: res,
                  successHandle,
                  errorHandle
                })
              })
              return
            }
            window.location = callbackUrl
          } else {

            apiReport.report({
              apiPath: 'inline/klarna/authorize',
              siteuid: gbCommonInfo.SiteUID,
              err: JSON.stringify(res)
            })

            _sendErrorReport?.('klarna_inline_auth_error', {
              failure_type: 'sdk',
              client_url: '/inline/klarna/authorize',
              status_code: '',
              description: JSON.stringify(res || {})
            })
            if (type === 'ocp') return errorHandle()
            if (fromCheckout) {
              location.reload()
            }
          }
        }
      )
    } catch (e) {
      apiReport.report({
        apiPath: 'inline/klarna/authorize',
        siteuid: gbCommonInfo.SiteUID,
        err: JSON.stringify({ err: e })
      })
      if (fromCheckout) {
        location.reload()
      }
    }
  } else {
    if (fromCheckout) {
      location.reload()
    }
  }
}

/** 当action是redirect时，未获取到actionUrl需上报 */
function errorActionUrlReport({ payment_method, billno } = {}) {
  if (!billno || !payment_method) return
  apiReport.report({
    apiPath: 'payment/get_url_error',
    payment_method,
    billno
  })
}

export default { checkForm, unifiedPay }
